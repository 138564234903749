@keyframes fadeIn {
  0% {opacity: 0;}
  100% {opacity: 1;}
}

.loadingScreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ffbf5a;
  color: #886413;
  font-size: 1.5vw;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 20;
}


.app {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  text-align: center;
  overflow: auto;
  background-color: #ffbd7a;
}

.app canvas {
  position: fixed;
  z-index: 1;
  background-position: center;
}

body, html {
  height: 100%;
  width: 100%;
  overflow: hidden;
  font-family: 'Futura Book';
  cursor: default;
  background-color: #ffbd7a;
}

.contactInfo {
  position: absolute;
  top: 2vh;
  right: 4vw;
  z-index: 10;
  text-align: right;
  color: #886413;
}

@font-face {
  font-family: 'Futura Book';
  src: url('./fonts/Futura Book font.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'MuseumFoundry';
  src: url('./fonts/MuseumFoundry.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Garamond';
  src: url('./fonts/EBGaramond-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'GaramondBold';
  src: url('./fonts/EBGaramond-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.Writing {
  align-self: flex-start;
  margin-left: 9vw;
}

.title {
  font-weight: bold;
  font-size: 2rem;
}

.footerText {
  z-index: 2;
  letter-spacing: 1px;
  cursor: default;
  position: fixed;
  bottom: 2.5vh;
  right: 1vw;
  font-family: "Garamond", serif;
  text-align: right;
  font-size: 1.35vw;
  color: white;
  text-shadow: 0vh -0.568vh 1.42vh black, -1.42vh -1.42vh 0.71vh black,  -0.426vh -0.426vh 2.84vh black,  -0.284vh -0.284vh 2.84vh black;
  animation: fadeIn 0.8s ease-in;
  opacity: 1;
}


.footerTextTop {
  padding: 0.426vh;
  padding-top: 0.426vh;
  margin: 0.426vh;
  margin-bottom: 0.5vh;
  margin-left: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
  font-style: italic;
  font-weight: bold;
}

.writingContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}


.divider {
  padding: 0 2.5px;
}

.divider:hover {
  cursor: default;
}

.app .text,
.app .links {
  z-index: 2;
  font-size: 3vw;
  font-weight: bold;
}

.image {
  height: 100%; 
  width: auto;
}

.image-top {
  width: 100%;
  height: auto;
}


.text {
  position: relative;
  color: #886413;
  font-size:28px;
  text-align: center;
}

.transitionText {
  cursor: pointer;
}

.text span:hover:not(.divider) {
  color: rgba(255, 255, 255, 0.7);
}

.app .back-button {
  z-index: 2;
  background: none !important;
  border: none !important;
  padding: 0 !important;
  font-family: 'Futura Book', sans-serif;
  cursor: pointer;
  position: absolute;
  top: 2.27vh;
  left: 2vw;
  color: white;
  font-size: 4.26vh;
  text-shadow: 0px 0px 1px #000000, 0px 0px 2px #000000, 0px 0px 3px #000000, 0px 0px 4px #000000, 0px 0px 5px #000000, 0px 0px 6px #000000, 0px 0px 7px #000000;
}

.customHr,
.codeBar {
  width: 20vw;
  height: 1px;
  border: none;
  margin: 0;
}

.customHr {
  background-color: black;
  margin: 1.5% 0 1.5% 0;
}

.codeBar {
  background-color: white;
  margin-bottom: 0px;
}

.sectionContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 86vh;
  width: 32vw;
  background-size: 100% 100%;
  background-position: center;
  opacity: 0;
  animation: fadeIn 0.25s 0.5s ease-in forwards;
  padding: 10% 15% 10% 15%;
  box-sizing: border-box;
}

.contentWrapper {
  height: 100%;
  width: 100%;
  overflow-y: auto;
}

div.section:nth-child(2) {
  margin: 8% 0 8% 0;
}

.section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.linksTitle {
  font-size: 2.5vw;
  text-align: left;
  margin-left: 0.3vw;
  margin-top:3vh;
  color: peachpuff;
  text-shadow: 0px 0px 1px #000000, 0px 0px 2px #000000, -2px 0px 3px #000000, 0px -3px 5px #000000;
}

.sectionTitle {
  font-size: 1.5rem;
  text-align: left;
  color: #bf9b30;
  text-shadow: 0px 0px 1px #000000, 0px 0px 2px #000000;
  font-family: "Garamond", serif;
}


.linkWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-top: 1vh;
}

.partiallyHyperlinked,
.sectionLink {
  font-family: "MuseumFoundry";
  font-size: 1rem;
  padding: 0;
  text-decoration: none;
  color: #bf4730;
  font-style: italic;
  text-shadow: 0px 0px 1px #000000, 0px 0px 2px #712a1c, -0.5px -1px 1px #000000;
  text-align: left;
  display: inline-block;
  cursor: pointer;
}

div.linkWrapper:nth-child(10) > span:nth-child(1) {
  text-indent: -2em;
  padding-left: 2em;
}

.otherText {
  color: #bf9b30;
  font-size: 1rem;
  cursor: default;
}

@keyframes appear {
  100% {
    opacity: 1;
  }
}

.unfurl {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  animation: unfurl 0.55s 0.7s ease forwards;
}

@keyframes unfurl {
  0% { clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%); }
  100% { clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0 100%); }
}

.mask {
  position: absolute;
  top: -8vh;
  left: 0;
  width: 100%;
  height: 8vh;
  overflow: hidden;
  background-color: #BF8756;
  animation: moveDown 0.55s 0.7s ease forwards, unfurl 0.55s 0.7s ease forwards;
}

.maskGrid {
  width: 100%;
  height: 150%;
  background-size: 5px 5px;
  background-image: repeating-linear-gradient(45deg, black, black 2px, transparent 2px, transparent 4px);
}

@keyframes spin {
  0% { transform: rotateX(0deg) }
  100% { transform: rotateX(90deg)  }
}

@keyframes moveDown {
  0% { top: -8vh; }
  100% { top: 90%; }
}

@keyframes fadeOut {
  0% { opacity: 1; }
  100% { opacity: 0; }
}

@keyframes show {
  0% { display: none; }
  100% { display: flex; }
}


.imageGallery {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(10vw, 1fr)); 
  gap: 10px;
  justify-content: center;
  align-content: center;
  width: 90vw;
  overflow: auto;
  cursor: default;
  animation: fadeIn 0.8s ease-in;
  opacity: 1;
}

.imageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
  margin: 0px;
  border: 0px;
  animation: fadeIn 1.5s ease-in;
  opacity: 1;
}



.shamu-container,
.writingSample {
    animation: fadeIn 0.4s ease-in;
}

.shamu-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;  
}

.writingSample {
  height: 90vh;
  max-width: 75vw;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: white;
  overflow: auto;
  padding: 4vh 3vw 4vh 3vw;
  box-sizing: border-box;
}


.shamu-sample {
  padding: 0;
  height: 84vh;
  width: 44vw;
}

.sample-container:not(.shamu-container) {
  width: 40%;
  height: 100%;
  font-family: "Garamond", serif;
  font-size: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
  line-height: 1.5;
  gap: 2vh;
  overflow-y: auto;
  padding-right: 3vw;
}

div.paragraph.ashes-paragraph:nth-child(6) {
  font-family: "GaramondBold", serif;
  font-weight: bold;
}

.shamu-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

div.paragraph.ashes-paragraph:nth-child(22),
div.paragraph.ashes-paragraph:nth-child(25),
div.paragraph.mccartney-paragraph:nth-child(7),
div.paragraph.mccartney-paragraph:nth-child(9),
div.paragraph.newpresident-paragraph:nth-child(2) {
    font-style: italic
}

.arial-word {
  font-family: Arial;
}

.mob-word,
.ashes-word {
  font-style: italic;
}

.no-animation {
  animation: none;
  opacity: 1;
}

.sample-open .mask, .sample-open .unfurl {
  display: none !important;
}


.codeLinks {
  display: inline;
  font-size: 1.35vw; 
  text-shadow: 0px 0px 1px #000000, 0px 0px 2px #000000, -2px 0px 3px #000000, 0px -3px 5px #000000,  -2px -2px 1px #000000, 1px 0px 2px #000000, 0px 0px 4px #000000, 0px 0px 7px #000000, -1px -1px 2px #000000;
  color: #ff3c12;
  text-decoration: none;
  margin-top: 0px;

}

.codeDescriptions {
  display: inline;
  font-size: 1.15vw; 
  color: white;
  text-shadow: 0px 0px 1px #000000, 0px 0px 2px #000000, -2px 0px 3px #000000, 0px -3px 5px #000000,  -2px -2px 1px #000000, 1px 0px 2px #000000, 0px 0px 4px #000000, 0px 0px 7px #000000, -1px -1px 2px #000000;
  cursor: default;
  margin-top: 0px;
}


.sectionLink:hover,
.links a:hover {
  color: rgba(255, 255, 255, 0.7);
}

.linkContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  max-width: 100vw;
  top: 10vh;
  margin-bottom: 5vh;
  padding: 4.26vh;
  padding-top: 0;
  cursor: default;
  background-color: rgba(0, 0, 0, 0.8);
  opacity: 0;  
  animation: fadeIn 0.25s 0.65s ease-in forwards;
}

.thumbnail {
  width: 100%; 
  object-fit: cover;
  padding: 0px;
  margin: 0px;
  cursor: pointer;
}

.thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
}

.videoContainer {
  cursor: default;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  max-width: 100vw;
  padding: 0px;
  opacity: 0;
  animation: fadeIn 0.35s  0.75s ease-in forwards;
  gap: 5vw;
}


.videoContainer img {
  width: 45vw;
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 2;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.85);
  overflow: auto;
}

.modal-content {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.modal-content img {
  max-width: 90vw;
  max-height: 90vh;
  object-fit: contain;
}

.modal-navigation {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.modal-navigation button {
  background: transparent;
  color: white;
  border: none;
  font-size: 2em;
  cursor: pointer;
  z-index: 10;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.modal-navigation button:first-child {
  left: 5%;
}

.modal-navigation button:last-child {
  right: 5%;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  color: white;
  font-size: 2em;
  cursor: pointer;
  z-index: 10;
}

.close:hover,
.close:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}

.youtubeThumbnailContainer {
    display: grid;
    grid-template-rows: repeat(3, 1fr);
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    height: 100%; 
    text-align: center;
    flex: 0.5;
    opacity: 0;
    animation: fadeIn 0.2s 0.75s ease-in forwards;
}

.youtubeThumbnail {
    position: relative;
    width: 10vw;
    height: 10vw;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.youtubeThumbnailBefore {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    opacity: 1;
    transition: opacity 0.3s ease;
}

.youtubeThumbnail:hover .youtubeThumbnailBefore {
    opacity: 0;
}

.youtubeThumbnailText {
    color: white;
    font-size: 1.7vw;
    text-shadow: 0px 0px 1px #000000, 0px 0px 2px #000000, -2px 0px 3px #000000, 0px -3px 5px #000000, -2px -2px 1px #000000, 1px 0px 2px #000000, 0px 0px 4px #000000, 0px 0px 7px #000000, -1px -1px 2px #000000, 0px 0px 1px #000000, 0px 0px 2px #000000, -2px 0px 3px #000000, 0px -3px 5px #000000, 0px 0px 1px #000000, 0px 3px 2px #000000, -2px 3px 4px #000000, 0px 0px 2px #000000, -2px 0px 3px #000000, 0px -3px 5px #000000, 0px 2px 1px #000000, -1px 0px 1px #000000, 1px 0px 1px #000000, 1px 0px 2px #000000;
    z-index: 1;
}

.youtubeThumbnail:hover .youtubeThumbnailText {
    display: none;
}

div.youtubeThumbnailContainer:nth-child(1) > div:nth-child(5),
div.youtubeThumbnailContainer:nth-child(3) > div:nth-child(5) {
  left: 55%;
}


.tiktokContainer {
  display: flex;
  flex-direction: row;
  width: 95vw;
  height: 75vh;
  margin-bottom: 5vh;
  gap: 1vw;
}

.youtubeThumbnailContainer > * {
  margin: auto;
}

.tiktoks {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  height: calc(75vh * 0.99);
  flex: 1;
  gap: 1vw;
  opacity: 0;
  animation: fadeIn 0.3s 1s ease-in forwards;
}

.tiktokWrapper {
  position: relative;
  height: 100%;
  width: 38vh;
  background-color: black;
}

.tiktokOverlayContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.tiktokOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
}

.tiktokText {
  position: relative;
  color: white;
  font-size: 3vw;
  text-shadow: 0px 0px 1px #000000, 0px 3px 2px #000000, -2px 3px 4px #000000, 0px 0px 2px #000000, -2px 0px 3px #000000, 0px -3px 5px #000000,  -2px -2px 1px #000000, 1px 0px 2px #000000, 0px 0px 4px #000000, 0px 0px 7px #000000, -1px -1px 2px #000000, 0px 0px 1px #000000, 0px 0px 2px #000000, -2px 0px 3px #000000, 0px -3px 5px #000000, 0px 0px 1px #000000, 0px 3px 2px #000000, -2px 3px 4px #000000, 0px 0px 2px #000000, -2px 0px 3px #000000, 0px -3px 5px #000000, 0px 0px 1px #000000, 0px 3px 2px #000000, -2px 3px 4px #000000, 0px 0px 2px #000000, -2px 0px 3px #000000, 0px -3px 5px #000000,  -2px -2px 1px #000000, 1px 0px 2px #000000, 0px 0px 4px #000000, 0px 0px 7px #000000, -1px -1px 2px #000000, 0px 0px 1px #000000, 0px 0px 2px #000000, -2px 0px 3px #000000, 0px -3px 5px #000000, 0px 0px 1px #000000, 0px 3px 2px #000000, -2px 3px 4px #000000, 0px 0px 2px #000000, -2px 0px 3px #000000, 0px -3px 5px #000000;
  z-index: 1;
  top: 45%;
}

.tiktokWrapper:hover .tiktokOverlayContainer {
  opacity: 0;
  transition: opacity 0.3s ease;
}

.tiktok-frame {
  width: 100%;
  height: 100%;
}

.modal-video {
  position: relative;
  width: 75%;
  height: 0;
  padding-bottom: 45%;
  overflow: hidden;
}

.modal-video iframe,
.modal-video lite-youtube {
  position: absolute;
  width: 100%;
  height: 100%;
}

.thumbnail-play-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.thumbnail-play {
  width: 100%;
  height: 100%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.video-stream {
  height: 100%;
}

lite-youtube {
    background-color: #000;
    position: relative;
    display: block;
    contain: content;
    background-position: center center;
    background-size: cover;
    cursor: pointer;
}

/* gradient */
lite-youtube::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAADGCAYAAAAT+OqFAAAAdklEQVQoz42QQQ7AIAgEF/T/D+kbq/RWAlnQyyazA4aoAB4FsBSA/bFjuF1EOL7VbrIrBuusmrt4ZZORfb6ehbWdnRHEIiITaEUKa5EJqUakRSaEYBJSCY2dEstQY7AuxahwXFrvZmWl2rh4JZ07z9dLtesfNj5q0FU3A5ObbwAAAABJRU5ErkJggg==);
    background-position: top;
    background-repeat: repeat-x;
    height: 60px;
    padding-bottom: 50px;
    width: 100%;
    transition: all 0.2s cubic-bezier(0, 0, 0.2, 1);
}

lite-youtube::after {
    content: "";
    display: block;
    padding-bottom: calc(100% / (16 / 9));
}
lite-youtube > iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border: 0;
}

/* play button */
lite-youtube > .lty-playbtn {
    width: 68px;
    height: 48px;
    position: absolute;
    cursor: pointer;
    transform: translate3d(-50%, -50%, 0);
    top: 50%;
    left: 50%;
    z-index: 1;
    background-color: transparent;
    /* YT's actual play button svg */
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 68 48"><path fill="%23f00" fill-opacity="0.8" d="M66.52,7.74c-0.78-2.93-2.49-5.41-5.42-6.19C55.79,.13,34,0,34,0S12.21,.13,6.9,1.55 C3.97,2.33,2.27,4.81,1.48,7.74C0.06,13.05,0,24,0,24s0.06,10.95,1.48,16.26c0.78,2.93,2.49,5.41,5.42,6.19 C12.21,47.87,34,48,34,48s21.79-0.13,27.1-1.55c2.93-0.78,4.64-3.26,5.42-6.19C67.94,34.95,68,24,68,24S67.94,13.05,66.52,7.74z"></path><path d="M 45,24 27,14 27,34" fill="%23fff"></path></svg>');
    filter: grayscale(100%);
    transition: filter .1s cubic-bezier(0, 0, 0.2, 1);
    border: none;
}

lite-youtube:hover > .lty-playbtn,
lite-youtube .lty-playbtn:focus {
    filter: none;
}

/* Post-click styles */
lite-youtube.lyt-activated {
    cursor: unset;
}
lite-youtube.lyt-activated::before,
lite-youtube.lyt-activated > .lty-playbtn {
    opacity: 0;
    pointer-events: none;
}

.lyt-visually-hidden {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }
